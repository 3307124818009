<template>
  <v-container fluid class="career-login height-100">
    <v-row :class="$vuetify.breakpoint.xs ? 'pt-75' : 'pt-150'">
      <v-spacer></v-spacer>
      <v-col cols="12" sm="12" md="8" lg="6" xl="5">
        <v-card tile>
          <v-card-title
            class="
              d-flex
              justify-space-between
              wide
              white--text
              elevation-5
              primary-bkg
              py-7
            "
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="8"
                class="d-flex justify-center justify-sm-start"
              >
                <v-img
                  width="300"
                  max-width="300"
                  :src="logoSrc"
                  contain
                  class="ml-3 h-40"
                ></v-img>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                class="d-flex justify-center justify-sm-end"
              >
                <div class="login-title pt-4 pt-sm-0 pr-md-4">Resume Tool</div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-system-bar height="5" color="accent" class=""></v-system-bar>
          <v-card-text>
            <span class="text-body-1 text--primary">
              You are an admin user. You must confirm your email to proceed.
            </span>
          </v-card-text>
          <v-divider />
          <v-card-actions class="d-flex justify-space-between align-end pb-3">
            <v-row class="px-1 pt-3">
              <v-col
                cols="12"
                sm="6"
                class="d-flex justify-center justify-sm-start align-end"
              >
                <div class="d-flex justify-center justify-sm-start flex-column">
                  <div>
                    <p class="text-body-2 text--secondary font-italic mb-0">
                      Already confirmed your email?
                    </p>
                  </div>
                  <v-btn color="gray" outlined @click="checkValid"
                    >Check Status</v-btn
                  >
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="
                  align-end
                  d-flex
                  flex-column
                  justify-center justify-sm-end
                "
              >
                <div class="text-body-1 text--primary">
                  Request a new confirmation email.
                </div>
                <v-btn
                  outlined
                  @click="sendConfirmEmail()"
                  :disabled="!confirmEmailEnabled"
                  :loading="confirmEmailLoading"
                  >{{ confirmEmailButtonText }}</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import { Env } from "../../environment/EnvironmentFactory";
import * as login from "../../api/LoginApi";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

// @ is an alias to /src
export default {
  name: "ConfirmEmailRequired",
  data: () => ({
    confirmEmailButtonText: "Re-Send Confirmation Email",
    confirmEmailEnabled: true,
    confirmEmailLoading: false,
    logoSrc: "",
  }),
  async mounted() {
    Env()
      .GetLogoFilename()
      .then((f) => {
        this.logoSrc = f;
      });
    await this.checkValid();
  },
  methods: {
    ...mapActions("auth", ["logUserIn"]),
    async sendConfirmEmail() {
      this.confirmEmailLoading = true;
      this.confirmEmailEnabled = false;

      const confirmEmailResp = await login.SendConfirmEmail();
      if (!confirmEmailResp.success) {
        this.emailErrorText = "Unable to send a confirmation email.";
        this.confirmEmailEnabled = true;
      } else {
        this.confirmEmailButtonText = "Confirmation Email Sent";
        this.confirmEmailEnabled = false;
      }
      this.confirmEmailLoading = false;
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    async checkValid() {
      await this.logUserIn();
      if (this.authType != "resumation" || this.emailConfirmed == true) {
        this.nav("/Login");
      }
    },
  },
  computed: {
    ...mapFields("auth", {
      emailConfirmed: "emailConfirmed",
      authType: "auth.authType",
    }),
  },
};
</script>

<style scoped>
.pt-150 {
  padding-top: 150px;
}

.pt-75 {
  padding-top: 75px;
}

.career-login {
  background-color: #f4f4f4;
}

.primary-bkg {
  background-color: var(--v-primary-base);
}

.h-40 {
  max-height: 40px;
}
</style>
